import { cn } from '@/core/ui/utils';

export const DEFAULT_BUTTON_SIZE = 'md';

export const createButtonClasses = ({
  round = 'borders',
  isLoading,
  color,
  size = DEFAULT_BUTTON_SIZE,
  width = 'w-auto',
  padding = 'default',
  isDisabled = false,
  className,
}: CreateButtonClassesConfig) =>
  cn(
    buttonBaseClasses,
    createButtonSizingClasses({ size, padding }),
    buttonColorClasses[color],
    buttonRoundClasses[round],
    round === 'full' ? 'rounded-full' : 'rounded-lg',
    { 'px-0': round === 'full' },
    width,
    isLoading ? 'disabled:cursor-wait' : 'disabled:cursor-not-allowed',
    isDisabled && 'pointer-events-none',
    className
  );

const createButtonSizingClasses = ({
  size,
  padding,
}: {
  size: ButtonSizeVariant;
  padding: ButtonPaddingVariant;
}) => cn(buttonSizingClasses[size], getButtonPadding(padding, size));

const getButtonPadding = (
  padding: ButtonPaddingVariant,
  size: ButtonSizeVariant
) => {
  if (padding === 'default') {
    return buttonPaddingClasses[size];
  }

  if (padding === 'none') {
    return 'px-0';
  }

  return buttonPaddingClasses[padding];
};

const tertiaryCounterNarrowClasses = cn(
  'bg-white',
  'cursor-pointer',
  'disabled:bg-surface-disabled',
  'disabled:cursor-not-allowed'
);

const buttonColorClasses = {
  'primary/primary': cn(
    'border-transparent',
    'text-white',
    'bg-primary-400',
    'focus-visible:ring-primary-200',
    'hover:bg-primary-600',
    'enabled:active:bg-primary-700',
    'disabled:bg-gray-100',
    'disabled:text-gray-400'
  ),
  'primary/secondary': cn(
    'border-primary-500',
    'border-2',
    'text-primary-500',
    'bg-transparent',
    'focus-visible:ring-primary-200',
    'hover:border-primary-600',
    'enabled:active:border-primary-700',
    'disabled:border-gray-100',
    'disabled:text-gray-400'
  ),
  'secondary/primary': cn(
    'border-transparent',
    'text-primary-400',
    'bg-primary-100',
    'focus-visible:ring-primary-200',
    'enabled:active:bg-primary-300',
    'disabled:bg-gray-100',
    'disabled:text-gray-400'
  ),
  'secondary/neutral': cn(
    'border-transparent',
    'text-gray-400',
    'bg-gray-100',
    'focus-visible:ring-primary-200',
    'hover:bg-gray-200',
    'enabled:active:bg-gray-300',
    'disabled:bg-gray-100',
    'disabled:text-gray-400'
  ),
  'error/primary': cn(
    'border-transparent',
    'text-white',
    'bg-error-500',
    'focus-visible:ring-error-200',
    'hover:bg-error-600',
    'enabled:active:bg-error-700',
    'disabled:bg-gray-100',
    'disabled:text-gray-400'
  ),
  'minimal/neutral': cn(
    'border-transparent',
    'text-gray-600',
    'bg-transparent',
    'focus-visible:ring-gray-200',
    'enabled:active:bg-gray-300',
    'disabled:text-gray-400'
  ),
  'minimal/primary': cn(
    'border-transparent',
    'text-primary-500',
    'bg-transparent',
    'focus-visible:ring-primary-200',
    'disabled:text-gray-400'
  ),
  'tertiary/primary/pagination': cn(
    'text-gray-600',
    'bg-transparent',
    'focus-visible:ring-primary-200',
    'disabled:text-gray-400',
    'disabled:border-none',
    'border-primary-100'
  ),
  'tertiary/primary/scheduler': cn(
    'border-primary-200',
    'border',
    'bg-white',
    'text-gray-900',
    'disabled:border-gray-200',
    'disabled:border',
    'disabled:bg-gray-050',
    'disabled:text-gray-300',
    'disabled:cursor-not-allowed'
  ),
  'tertiary/primary/scheduler-active': cn(
    'border-primary-300',
    'border',
    'bg-primary-050',
    'text-gray-900',
    'disabled:border-gray-200',
    'disabled:border',
    'disabled:bg-gray-050',
    'disabled:text-gray-300',
    'disabled:cursor-not-allowed'
  ),
  'tertiary/neutral/carousel': cn(
    'border-transparent',
    'text-gray-700',
    'bg-white',
    'focus-visible:ring-primary-200',
    'font-medium'
  ),
  'tertiary/primary/100': cn(
    'border-transparent',
    'text-primary-600',
    'bg-primary-100',
    'focus-visible:ring-primary-200',
    'hover:bg-primary-200',
    'enabled:active:bg-primary-700',
    'disabled:bg-gray-100',
    'disabled:text-gray-400',
    'font-medium'
  ),
  'tertiary/neutral': cn(
    'border',
    'border-gray-200',
    'bg-white',
    'text-gray-600',
    'disabled:opacity-50'
  ),
  'tertiary/gray/400': cn(
    'border-transparent',
    'text-gray-400',
    'bg-transparent',
    'focus-visible:ring-gray-200',
    'enabled:active:bg-gray-300',
    'disabled:opacity-50'
  ),
  'tertiary/gray/800': cn(
    'border-gray-300',
    'text-gray-800',
    'bg-transparent',
    'focus-visible:ring-gray-200',
    'enabled:active:bg-gray-300',
    'disabled:opacity-50'
  ),
  'tertiary/primary/500': cn(
    'border-transparent',
    'text-primary-500',
    'bg-gray-100',
    'focus-visible:ring-primary-200',
    'enabled:active:bg-primary-400',
    'disabled:opacity-50'
  ),
  'tertiary/primary/700': cn(
    'border-transparent',
    'text-primary-700',
    'bg-transparent',
    'focus-visible:ring-primary-200',
    'disabled:opacity-50'
  ),
  'tertiary/counterNarrow': tertiaryCounterNarrowClasses,
  'tertiary/counterNarrow/unfolded': tertiaryCounterNarrowClasses,
  'tertiary/counterNarrow/active': cn(
    'bg-primary-400',
    'text-white',
    'border-transparent'
  ),
  'tertiary/product/seeAll': cn(
    'bg-gray-050',
    'text-primary-600',
    'border-none'
  ),
  'tertiary/gray/750': cn('text-gray-700', 'bg-gray-100', 'rounded-full'),
  'tertiary/pagination': cn(
    'text-text-subtle',
    'hover:bg-action-tertiary-hovered',
    'hover:border-border-hovered',
    'disabled:cursor-not-allowed',
    'select-none'
  ),
  'tertiary/pagination/arrow': cn(
    'focus-visible:ring-primary-200',
    'text-text-subtle',
    'disabled:cursor-not-allowed',
    'select-none'
  ),
  'tertiary/pagination/current': cn(
    'bg-action-tertiary-pressed',
    'text-text-default',
    'select-none'
  ),
};

const buttonSizingClasses = {
  xs: cn('min-w-6', 'h-6', 'text-sm', 'font-medium'),
  sm: cn('min-w-8', 'h-8', 'text-sm', 'font-medium'),
  base: cn('min-w-[170px]', 'h-12', 'text-sm', 'font-semibold'),
  md: cn('min-w-10', 'h-10', 'text-base', 'font-medium'),
  '2md': cn('w-11', 'h-11', 'text-base', 'font-medium'),
  lg: cn('min-w-12', 'h-12', 'text-base', 'font-medium'),
};

const buttonPaddingClasses = {
  xs: 'px-4',
  sm: 'px-4',
  base: 'px-6',
  md: 'px-6',
  '2md': 'px-0',
  lg: 'px-8',
};

const buttonRoundClasses = {
  full: 'rounded-full',
  borders: 'rounded-lg',
  sm: 'rounded-sm',
  xl: 'rounded-xl',
  none: 'border-none',
};

const buttonBaseClasses = cn(
  'inline-flex',
  'items-center',
  'focus:outline-none',
  'focus-visible:ring-4',
  'transition-colors',
  'border',
  'cursor-pointer',
  'relative',
  'shrink-0'
);

export type CreateButtonClassesConfig = {
  round?: ButtonRoundVariant;
  isLoading?: boolean;
  color: ButtonColorVariant;
  size?: ButtonSizeVariant;
  width?: ButtonWidthVariant;
  padding?: ButtonPaddingVariant;
  isDisabled?: boolean;
  className?: string;
};
export type ButtonColorVariant = keyof typeof buttonColorClasses;
export type ButtonRoundVariant = keyof typeof buttonRoundClasses;
export type ButtonSizeVariant = keyof typeof buttonSizingClasses;
export type ButtonWidthVariant = 'w-auto' | 'w-full' | 'w-unset';
export type ButtonPaddingVariant = 'default' | 'none' | 'sm' | 'md' | 'lg';
